import { combineReducers } from 'redux';
import { GET_ENVIRONEMENT, GET_SIGNATURE, EDIT_FORM, RESET_READY_TO_PAY } from '../actions/signature-action';

const initialState = {
  signature: null,
  model: {},
  readyToPay: false
}

function environment(state = {}, action) {
  switch (action.type) {
    case GET_ENVIRONEMENT:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function getSignature(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }

  switch (action.type) {
    case GET_SIGNATURE:
    case EDIT_FORM:
      return { ...state, ...action.payload };
    case RESET_READY_TO_PAY:
      return {
        ...state,
        readyToPay: false
      }
    default:
      return state;
  }
}

const formReducer = combineReducers({
  environment,
  getSignature
});

export default formReducer;
