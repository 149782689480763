const paymentFields = [
  { name: "Case Number", value: "caseNumber", canEdit: false },
  {
    name: "Envelope Number",
    value: "envelopeNumber",
    placeHolder: "Enter Envelope Number Here if Applicable",
  },
  { name: "Amount", value: "amountStr", type: "money" },
  {
    name: "Payment Method",
    value: "payment_method",
    defaultValue: "Card",
    canEdit: false
  },
];

export default paymentFields;
