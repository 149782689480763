import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { Field } from 'formik';
import NumberFormat from 'react-number-format';

const FieldMapper = ({ field: x, errors, touched, values, formModel, setFieldValue, max, canAdjustAmount, disableOn }) => {
    return (
        <div className={`form-group row`}>
            <label className="col-sm-4 control-label">{x.name}</label>
            <div className={"col-sm-8 field-mapper " + x.value}>
                {/* Select */}
                {
                    x.type === 'select' &&
                    <>
                        <Field as="select" name={x.value} className="form-control" disabled={formModel.readyToPay || x.canEdit === false}>
                            <option value="" >{x.selectHolder}</option>
                            {
                                x.options.map(j => (
                                    <option key={j.abbr} value={j.abbr}>{j.name}</option>
                                ))
                            }
                        </Field>
                    </>
                }

                {/* Money / Amount */}
                {
                    x.type === 'money' &&
                    <Field name={x.value} value={values[x.value]} className="form-control" disabled={formModel.readyToPay || x.canEdit === false || !canAdjustAmount}
                        validate={(e) => {
                            if (e > max)
                                return `Amount must be less than or equal to the current balance $${parseFloat(max).toFixed(2)}`;
                            if (e <= 0)
                                return `Amount must be more than $0.00`;
                        }}
                        render={({ field }) => {
                            return (
                                <NumberFormat {...field} value={values[x.value]} className="form-control alignRight" disabled={formModel.readyToPay || x.canEdit === false || !canAdjustAmount}
                                    prefix={"$"}
                                    thousandSeparator={true}
                                    onChange={(e) => {
                                        try {
                                            const currentValue = e.currentTarget.value
                                                .replace("$", "")
                                                .replace(".", "")
                                                .replace(",", "");
                                            var numberPattern = /\d+/g;

                                            let v = "0.00";

                                            // null input
                                            if (currentValue == "" || currentValue == null || parseFloat(currentValue) == 0)
                                                setFieldValue(x.value, "0.00");
                                            else {
                                                v = (parseFloat(currentValue.match(numberPattern).join('')) / 100).toFixed(2);
                                                setFieldValue(x.value, v);
                                            }
                                        } catch {
                                            setFieldValue(x.value, "0.00");
                                        }
                                    }}
                                />
                            )
                        }}
                    />
                }

                {/* Number */}
                {
                    x.type === 'number' &&
                    <Field name={x.value} value={values[x.value]} className="form-control" disabled={formModel.readyToPay || x.canEdit === false}
                        render={({ field }) => {
                            return (<NumberFormat {...field} className="form-control" disabled={formModel.readyToPay || x.canEdit === false} />)
                        }} />
                }

                {/* Text */}
                {
                    !x.type &&
                    <Field name={x.value} className="form-control"
                        disabled={formModel.readyToPay || x.canEdit === false || disableOn}
                        autoComplete={x.value}
                        placeholder={x.placeHolder ?? ''}
                    />
                }

                {/* Error goes here... */}
                {errors[x.value] && touched[x.value] ? (
                    <div className="alert alert-warning">{errors[x.value]}</div>
                ) : null}

                {/* Card fee warning */}
                {
                    (x.value === 'payment_method' && values.payment_method?.toLowerCase() === "card") &&
                    <div className='alert alert-primary' style={{ marginTop: "10px" }}>
                        <p>A service fee of 2.65% per credit card transaction will be charged.</p>
                        <p>The service fee is collected by a third party, and will be represented on your credit card statement as a separate transaction.</p>
                    </div>
                }
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return { formModel: state.formReducer.getSignature }
}

export default connect(
    mapStateToProps, {}
)(FieldMapper)