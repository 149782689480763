import React from "react";
import { useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";

const CaseBalanceTable = ({ selectedParty, caseModel }) => {
    let history = useHistory();

    return (
        <div className="card">
            <div className="card-body p-0 fee-schd-panel-body">
                <div className="table-responsive">
                    <table className="table case-balance-table table-hover table-striped">
                        <thead>
                            <tr>
                                <td>Fee Schedule</td>
                                <td className="alignRight">Total Charges</td>
                                <td className="alignRight">Total Payments</td>
                                <td className="alignRight">Balance</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedParty.feeSchedules.map((f, i) => (
                                <React.Fragment key={f.feeSchdCode}>
                                    <tr
                                        key={f.feeSchdCode}
                                        className={i % 2 ? "even" : "odd"}
                                    >
                                        <td
                                            style={{
                                                paddingLeft: "30px",
                                                verticalAlign: "middle",
                                            }}
                                        >
                                            {f.feeSchdDescription}
                                        </td>
                                        <td className="alignRight">
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                value={f.totalCharges}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </td>
                                        <td className="alignRight">
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                value={f.totalPayments}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </td>
                                        <td className="alignRight">
                                            <NumberFormat
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                                value={f.partyBalance}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                            />
                                        </td>
                                        <td>
                                            {!caseModel.odyCase.isCriminal && (
                                                <>
                                                    {f.totalPayments !==
                                                    f.totalCharges ? (
                                                        // Pay button
                                                        <button
                                                            className="btn btn-light btn-block btn-outline-primary"
                                                            onClick={() => {
                                                                history.push(
                                                                    `/payment-info/${caseModel.odyCase.caseNbr}/${selectedParty.partyID}/${f.feeSchdCode}`
                                                                );
                                                            }}
                                                        >
                                                            Pay
                                                        </button>
                                                    ) : (
                                                        // Disable button
                                                        <button
                                                            className="btn btn-default disabled"
                                                            disabled
                                                        >
                                                            Pay
                                                        </button>
                                                    )}
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </React.Fragment>
                            ))}
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                    <button
                                        className="btn btn-primary btn-block"
                                        onClick={() => {
                                            history.push(
                                                `/payment-info/${caseModel.odyCase.caseNbr}/${selectedParty.partyID}`
                                            );
                                        }}
                                    >
                                        {" "}
                                        {!caseModel.odyCase.isCriminal
                                            ? "Pay In Full"
                                            : "Pay"}
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CaseBalanceTable;
