export const GET_SIGNATURE = "GET_SIGNATURE";
export const ADD_LOCK_TO_CASE = "ADD_LOCK_TO_CASE";
export const EDIT_FORM = "EDIT_FORM";
export const GET_ENVIRONEMENT = "GET_ENVIRONEMENT";
export const RESET_READY_TO_PAY = "RESET_READY_TO_PAY";

export const getEnvironement = () => async (dispatch) => {
  const environment = await (
    await fetch("/api/Environment/GetEnvironment", {
      method: "POST",
    })
  ).json();
  console.log(environment);
  dispatch({
    type: GET_ENVIRONEMENT,
    payload: environment,
  });
  return environment;
};

export const getSignature = (model) => async (dispatch) => {
  const result = await (
    await fetch("/api/Signature/GetSignature", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(model),
    })
  ).json();
  console.log(result);
  dispatch({
    type: GET_SIGNATURE,
    payload: {
      model: result,
      signature: result.signature,
      readyToPay: true,
    },
  });
};

export const resetReadyToPay = () => async (dispatch) => {
  dispatch({
    type: RESET_READY_TO_PAY,
    payload: false,
  });
}

export const addLockToCase = (referenceID) => async (dispatch) => {
  const result = await (
    await fetch("/api/Signature/AddPendingPaymentLock", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ referenceID }),
    })
  ).json();
  console.log(result);
  dispatch({
    type: ADD_LOCK_TO_CASE,
    payload: result,
  });
};

export const editForm = () => async (dispatch) => {
  dispatch({
    type: EDIT_FORM,
    payload: {
      signature: null,
      readyToPay: false,
    },
  });
};
