import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { getCase } from "../../actions/case-action";
import PendingPaymentLockWarning from "../../components/warning/PendingPaymentLockWarning";
import CaseInfoTable from "../../components/shared-components/CaseInfoTable";

const schema = Yup.object().shape({
  caseNumber: Yup.string()
    .min(2, "Case number not valid")
    .max(20, "Case number not valid")
    .required("Case number is required"),
});

const Home = ({ getCase, caseModel }) => {
  let history = useHistory();
  const [odyCase, setOdyCase] = useState({});
  const [caseLock, setCaseLock] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div className="col">
        <hr />
        <Formik
          initialValues={{ caseNumber: "" }}
          validationSchema={schema}
          onSubmit={async (values) => {
            setIsLoading(true);
            const { odyCase: resultOdyCase, pendingPaymentLock } =
              await getCase(values.caseNumber);

            if (resultOdyCase && pendingPaymentLock == null) {
              setOdyCase(resultOdyCase);
              setCaseLock(null);

              // Found case, have fee, then redirects
              if (resultOdyCase.odyCaseParties?.length > 0)
                history.push(`/case-balance/${resultOdyCase.caseNbr}`);
            } else if (pendingPaymentLock != null) {
              setOdyCase(null);
              setCaseLock(pendingPaymentLock);
            } else {
              setOdyCase(null);
              setCaseLock(null);
            }
            setIsLoading(false);
          }}
        >
          {({ errors, touched }) => (
            <>
              <Form>
                <div className="row">
                  <div className="col-12">
                    <div className="jumbotron">
                      <h4 className="display-6 text-center">
                        Eighth Judicial District Court
                        <br />
                        Online Payment
                      </h4>
                      <hr className="my-4" />
                      {!isLoading && (
                        <>
                          <p className="lead">
                            To make a payment on your case, please enter the
                            full case number, e.g., C-12-345678-1:
                          </p>
                          <p>
                            <Field
                              name="caseNumber"
                              className="form-control caseNumberInput"
                            />
                          </p>

                          {/* Field input error */}
                          {errors.caseNumber && touched.caseNumber ? (
                            <div className="alert alert-warning">
                              {errors.caseNumber}
                            </div>
                          ) : null}

                          {/* Case not found warning */}
                          {odyCase == null && caseLock == null && (
                            <div className="alert alert-warning">
                              No case found. Please double check the case
                              number.
                            </div>
                          )}

                          {/* Pending lock warning */}
                          {odyCase == null && caseLock && (
                            <PendingPaymentLockWarning
                              lockTime={caseLock.lockTime}
                            />
                          )}

                          {/* No fees warning */}
                          {odyCase && odyCase.odyCaseParties?.length === 0 && (
                            <div className="row">
                              <div className="col">
                                <CaseInfoTable odyCase={odyCase} />
                                <p>No fees are due.</p>
                                <hr />
                              </div>
                            </div>
                          )}

                          <p className="lead">
                            <button
                              className="btn btn-primary btn-block-if-sm"
                              type="submit"
                            >
                              Find my case
                            </button>
                          </p>
                        </>
                      )}

                      {/* loading */}
                      {isLoading && (
                        <>
                          <div className="alert alert-primary">
                            <FontAwesomeIcon
                              icon={faCircleNotch}
                              spin={true}
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />{" "}
                            <span>&nbsp; Searching your case...</span>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return { caseModel: state.caseReducer.getCase };
}

const mapDispatchToProps = {
  getCase,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
