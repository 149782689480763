import React from "react";
import { useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";

const CaseBalanceTableCompact = ({ selectedParty, caseModel }) => {
    let history = useHistory();
    console.log("Small screen now!");
    return (
        <>
            {/* Do your thing here Parick */}
            <button
                className="btn btn-primary btn-block mb-3"
                onClick={() => {
                    history.push(
                        `/payment-info/${caseModel.odyCase.caseNbr}/${selectedParty.partyID}`
                    );
                }}
            >
                {" "}
                {!caseModel.odyCase.isCriminal
                    ? "Pay In Full - " + selectedParty.totalBalances
                    : "Pay"}
            </button>
            {selectedParty.feeSchedules.map((f, i) => (
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <b>{f.feeSchdDescription}</b>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">Charges</div>
                            <div className="col">
                                <NumberFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    value={f.totalCharges}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">Payments</div>
                            <div className="col">
                                <NumberFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    value={f.totalPayments}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">Balance</div>
                            <div className="col">
                                <NumberFormat
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    value={f.partyBalance}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {!caseModel.odyCase.isCriminal && (
                                    <>
                                        {f.totalPayments !== f.totalCharges ? (
                                            // Pay button
                                            <button
                                                className="btn btn-light btn-block btn-outline-primary mt-2"
                                                onClick={() => {
                                                    history.push(
                                                        `/payment-info/${caseModel.odyCase.caseNbr}/${selectedParty.partyID}/${f.feeSchdCode}`
                                                    );
                                                }}
                                            >
                                                Pay This Fee
                                            </button>
                                        ) : (
                                            // Disable button
                                            <button
                                                className="btn btn-default disabled mt-2"
                                                disabled
                                            >
                                                Pay This Fee
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default CaseBalanceTableCompact;
