export const GET_CASE = "GET_CASE";

export const getCase = (caseNbr) => async dispatch => {
    let foundCase;

    try {
        foundCase = await (await fetch("/api/Case/GetCase", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ caseNbr })
        })).json();
    } catch {
    }

    await dispatch({
        type: GET_CASE,
        payload: foundCase
    });

    return foundCase;
}