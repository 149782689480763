import { combineReducers } from 'redux';
import { GET_CASE } from '../actions/case-action';

const initialState = {
  odyCase: null
}

function getCase(state, action) {
  if (typeof state === 'undefined') {
    return initialState
  }
  
  switch (action.type) {
    case GET_CASE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const caseReducer = combineReducers({
  getCase
});

export default caseReducer;

