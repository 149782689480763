const GLOBAL_MEDIA_QUERIES = {
    small: "(max-width: 599px)",
    medium: "(min-width: 600px) and (max-width: 1199px)",
    large: "(min-width: 1200px)",
    bootstrap_xs: "(max-width: 575.98px)",
    bootstrap_sm: "(min-width: 576px) and (max-width: 767.98px)",
    bootstrap_md: "(min-width: 768px) and (max-width: 991.98px)",
    bootstrap_lg: "(min-width: 992px) and (max-width: 1299.98px)",
    bootstrap_xl: "(min-width: 1200px)",
};

export default GLOBAL_MEDIA_QUERIES;
