import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  getSignature,
  editForm,
  addLockToCase,
  resetReadyToPay,
} from "../../actions/signature-action";
import { getCase } from "../../actions/case-action";
import { v1_1_schema } from "./form-components/form-schema";
import {
  contactFields_fist_last_name,
  contactFields_company,
} from "./form-components/contact-fields";
import paymentFields from "./form-components/payment-fields";
import PendingPaymentLock from "../../components/warning/PendingPaymentLockWarning";
import FieldMapper from "./form-components/FieldMapper";

const fields = [
  ...contactFields_fist_last_name,
  ...contactFields_company,
  ...paymentFields,
];
const initValues = {};
fields.forEach((x) => {
  initValues[x.value] = x.defaultValue ?? "";
});

const PaymentForm = ({
  // redux actions
  getCase,
  getSignature,
  editForm,
  addLockToCase,
  resetReadyToPay,

  // redux props
  caseModel,
  formModel,
  environment,
}) => {
  const formEl = useRef(null);
  const [currentFeeScheduleCodes, setCurrentFeeScheduleCodes] = useState([]);
  const [isPayInFull, setIfPayInFull] = useState();
  const [canAdjustAmount, setCanAdjustAmount] = useState();
  const [totalBalance, setTotalBalance] = useState(0);
  const [cybersourceFormUrl, setCybersourceFormUrl] = useState();
  const [currentParty, setCurrentParty] = useState();
  const { caseNumber, partyID, feeScheduleCode } = useParams();
  const history = useHistory();
  const [currentPendingPaymentLock, setCurrentPendingPaymentLock] =
    useState(null);
  const [isRedirectingToCS, setIsRedirectingToCS] = useState(false);

  useEffect(() => {
    if (environment?.apiEnviroment) {
      const url = environment.apiEnviroment.isProduction
        ? "https://secureacceptance.cybersource.com/pay"
        : "https://testsecureacceptance.cybersource.com/pay";

      setCybersourceFormUrl(url);
      console.log(url);
    }
    return () => {};
  }, [environment]);

  useEffect(() => {
    const init = async () => {
      let foundCase;
      const payInFull =
        feeScheduleCode == null || feeScheduleCode?.trim() === "";
      setIfPayInFull(payInFull);

      if (caseModel.odyCase == null) {
        const { odyCase: resultOdyCase, pendingPaymentLock } = await getCase(
          caseNumber
        );
        foundCase = resultOdyCase;

        // Not found, redirects back to home
        // 2021-03-26 If case is criminal and it is not paying in full, redirect back to home
        // Or no case fee
        if (
          pendingPaymentLock != null ||
          foundCase == null ||
          foundCase?.odyCaseParties?.length === 0 ||
          (!payInFull && foundCase.isCriminal)
        ) {
          history.push("/");
          return;
        }
      } else foundCase = caseModel.odyCase;

      const foundParty = foundCase.odyCaseParties.find(
        (x) => x.partyID.toString() === partyID
      );
      const foundFeeSchdCode = foundParty?.feeSchedules.find(
        (f) => f.feeSchdCode === feeScheduleCode
      );
      const canAdjustPaymentAmount = foundCase.isCriminal || !payInFull;

      setCanAdjustAmount(canAdjustPaymentAmount);
      setCurrentParty(foundParty);

      // not balance found, redirects back to home
      if (!foundParty || (foundFeeSchdCode == null && !payInFull))
        history.push("/");
      else {
        if (payInFull) {
          setCurrentFeeScheduleCodes(foundParty.feeSchedules.map((x) => x));
          const tb = parseFloat(
            foundParty.feeSchedules
              .map((x) => x.partyBalance)
              .reduce((a, b) => a + b, 0)
              .toFixed(2)
          );
          setTotalBalance(tb);
        } else {
          setCurrentFeeScheduleCodes([foundFeeSchdCode]);
          setTotalBalance(foundFeeSchdCode.partyBalance);
        }
      }
    };
    init();
  }, []);

  return (
    <div className="panel-body">
      {/* odyCase and fee is not null */}
      {caseModel.odyCase != null &&
        currentFeeScheduleCodes.length > 0 &&
        currentParty != null && (
          <>
            <div className="col">
              <button
                className="btn btn-light"
                onClick={async () => {
                  history.push(`/case-balance/${caseModel.odyCase.caseNbr}`);
                  await editForm();
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Back to Case
                Balance Search
              </button>
              <hr />
            </div>
            <div className="col">
              <div className="alert alert-warning payment-alert-warning">
                <p>
                  <b>Do not submit multiple payments for this fee.</b> It may
                  take up to 20 minutes for your payment to post. Please allow
                  the system time to update.
                </p>
              </div>
              <div className="alert alert-info gary-background">
                {!isPayInFull ? (
                  <span>
                    {caseModel.odyCase.caseNbr} -{" "}
                    <b>{currentParty.partyName}</b> -{" "}
                    <i>{currentFeeScheduleCodes[0].feeSchdDescription}</i> | $
                    {parseFloat(
                      currentFeeScheduleCodes[0].partyBalance
                    ).toFixed(2)}
                  </span>
                ) : (
                  <>
                    <span>
                      {caseModel.odyCase.caseNbr} -{" "}
                      <b>{currentParty.partyName}: </b>
                    </span>
                    <table className="table table-sm">
                      <tbody>
                        {currentFeeScheduleCodes.map((x) => (
                          <tr key={x.feeScheduleInstanceID}>
                            <td>
                              <i>{x.feeSchdDescription}</i>
                            </td>
                            <td>
                              <NumberFormat
                                className="alignRight"
                                fixedDecimalScale={true}
                                decimalScale={2}
                                value={x.partyBalance}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"$"}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>

              <Formik
                initialValues={{
                  ...initValues,
                  caseNumber: caseModel.odyCase.caseNbr,
                  amountStr: !isPayInFull
                    ? currentFeeScheduleCodes[0].partyBalance.toFixed(2)
                    : totalBalance.toFixed(2),

                  caseID: currentParty.caseID,
                  partyID: currentParty.partyID,
                  partyName: currentParty.partyName,
                  partyTypeCode: currentParty.partyTypeCode,
                  partyTypeDescription: currentParty.partyTypeDescription,

                  feeSchedules: currentFeeScheduleCodes,
                  isCriminal: caseModel.odyCase.isCriminal,
                  isPayInFull: isPayInFull,
                }}
                enableReinitialize={true}
                validationSchema={v1_1_schema}
                onSubmit={async (values, formikHelpers) => {
                  // Only First&Last Name OR Business name is required
                  const hasFirstName = values.bill_to_forename.trim() !== "";
                  const hasLastName = values.bill_to_surname.trim() !== "";
                  const hasBusinessName =
                    values.bill_to_company_name.trim() !== "";

                  if (
                    (hasFirstName && hasLastName && hasBusinessName) ||
                    (!hasFirstName && hasLastName && hasBusinessName) ||
                    (hasFirstName && !hasLastName && hasBusinessName)
                  ) {
                    formikHelpers.setFieldError(
                      "nameError",
                      "Only either First & Last Name OR Business Name is required."
                    );
                  } else if (hasFirstName && !hasLastName)
                    formikHelpers.setFieldError(
                      "bill_to_surname",
                      "Last Name is required."
                    );
                  else if (!hasFirstName && hasLastName)
                    formikHelpers.setFieldError(
                      "bill_to_forename",
                      "First Name is required."
                    );
                  else if (!hasFirstName && !hasLastName && !hasBusinessName) {
                    formikHelpers.setFieldError(
                      "nameError",
                      "Please fill in either First & Last Name OR Business Name."
                    );
                  } else {
                    setIsRedirectingToCS(false);
                    setCurrentPendingPaymentLock(null);

                    if (formModel.readyToPay) {
                      // Check if there is a lock, if not add one
                      const getCaseRes = await getCase(
                        caseModel.odyCase.caseNbr
                      );
                      if (getCaseRes.pendingPaymentLock) {
                        // Show warning if there is one
                        setCurrentPendingPaymentLock(
                          getCaseRes.pendingPaymentLock
                        );
                        resetReadyToPay();
                        return;
                      } else if (formModel.model) {
                        setIsRedirectingToCS(true);
                        // Add lock
                        await addLockToCase(formModel.model.referenceID);
                        // Post to cybersource
                        formEl.current.submit();
                      }
                    } else await getSignature(values);
                  }
                }}
              >
                {({ errors, touched, values, setFieldValue, isSubmitting }) => (
                  <Form autoComplete="off">
                    <div className="row">
                      <div className="col">
                        <div className="card">
                          <div className="card-header">
                            <span>Payment Information</span>
                          </div>
                          <div className="card-body">
                            {/* Name error warning */}
                            <div>
                              {errors.nameError ? (
                                <div className="alert alert-warning">
                                  {errors.nameError}
                                </div>
                              ) : null}
                            </div>
                            {/* First and Last Name */}
                            {contactFields_fist_last_name.map((x) => {
                              return (
                                <FieldMapper
                                  key={x.name}
                                  field={x}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  disableOn={
                                    values.bill_to_company_name.trim() !== ""
                                  }
                                />
                              );
                            })}
                            {/* OR divider */}
                            <div className="form-group row">
                              <div className="col-sm-4 control-label"></div>
                              <div className="col-sm-8">
                                <div
                                  style={{ width: "30px", margin: "0 auto" }}
                                >
                                  <i>
                                    <b>OR</b>
                                  </i>
                                </div>
                              </div>
                            </div>
                            {/* Business Name */}
                            {contactFields_company.map((x) => {
                              return (
                                <FieldMapper
                                  key={x.name}
                                  field={x}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  disableOn={
                                    values.bill_to_forename.trim() !== "" ||
                                    values.bill_to_surname.trim() !== ""
                                  }
                                />
                              );
                            })}
                            <hr />
                            {/* Other fields */}
                            {paymentFields.map((x) => {
                              return (
                                <FieldMapper
                                  key={x.name}
                                  field={x}
                                  errors={errors}
                                  touched={touched}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  max={totalBalance}
                                  canAdjustAmount={canAdjustAmount}
                                />
                              );
                            })}
                          </div>
                        </div>
                        <div className="form-group form-button-group">
                          {formModel.readyToPay && (
                            <div className="alert alert-success">
                              Please confirm that all of your information is
                              correct. Press “Continue to secured payment page”
                              to submit payment or press “Back” to edit your
                              information.
                            </div>
                          )}
                          {currentPendingPaymentLock && (
                            <PendingPaymentLock
                              lockTime={currentPendingPaymentLock.lockTime}
                            />
                          )}
                          <hr />
                          {!formModel.readyToPay && (
                            <button
                              className="btn btn-primary btn-case-balance float-right mb-3"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Continue
                            </button>
                          )}
                          {formModel.readyToPay && (
                            <>
                              <button
                                className="btn btn-light btn-case-balance gray-background float-right mb-3"
                                disabled={isSubmitting || isRedirectingToCS}
                                type="button"
                                onClick={editForm}
                              >
                                Back
                              </button>
                              <button
                                className="btn btn-primary btn-case-balance float-right mb-3"
                                type="submit"
                                disabled={isSubmitting || isRedirectingToCS}
                              >
                                {isSubmitting || isRedirectingToCS ? (
                                  <>
                                    <div
                                      className="spinner-border spinner-border-sm mr-2"
                                      role="status"
                                    >
                                      <span className="sr-only">
                                        Loading...
                                      </span>
                                    </div>
                                    Loading...
                                  </>
                                ) : (
                                  <>Continue to secured payment page</>
                                )}
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>

              {/* form to post to cybersource */}
              {formModel.readyToPay && (
                <div className="hide">
                  <form
                    ref={formEl}
                    id="paymentForm"
                    method="post"
                    action={cybersourceFormUrl}
                  >
                    {formModel.model.formData.map((x) => (
                      <input
                        key={x.name}
                        name={x.name}
                        value={x.value ?? ""}
                        readOnly
                      />
                    ))}
                    <input
                      name="signature"
                      value={formModel.signature}
                      readOnly
                    />
                  </form>
                </div>
              )}
            </div>
          </>
        )}
      {/* loading */}
      {(caseModel.odyCase == null ||
        currentFeeScheduleCodes.length === 0 ||
        currentParty == null) && (
        <>
          <div className="col">
            <div className="alert alert-info">
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin={true}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <span>&nbsp; Loading...</span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    formModel: state.formReducer.getSignature,
    caseModel: state.caseReducer.getCase,
    environment: state.formReducer.environment,
  };
}

const mapDispatchToProps = {
  getCase,
  getSignature,
  editForm,
  addLockToCase,
  resetReadyToPay,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
