import * as Yup from 'yup';

export const v1_1_schema = Yup.object().shape({
    payment_method: Yup.string()
        .required('Required'),
    envelopeNumber: Yup.string()
        .max(9, "Efile envelope IDs cannot be more than 9 characters. Please re-enter the correct ID or skip this field if it’s not applicable to this payment."),

    bill_to_forename: Yup.string()
        .max(60, "First name cannot be more than 60 characters.")
        .matches(/^[a-zA-Z0-9 \-\/]+$/, "Only alphanumeric characters are allowed for this field "),
    bill_to_surname: Yup.string()
        .max(60, "Last name cannot be more than 60 characters.")
        .matches(/^[a-zA-Z0-9 \-\/]+$/, "Only alphanumeric characters are allowed for this field "),
    bill_to_company_name: Yup.string()
        .max(40, "Business name cannot be more than 40 characters.")
        .matches(/^[a-zA-Z0-9 \-\/]+$/, "Only alphanumeric characters are allowed for this field "),
});

export default v1_1_schema;