
import moment from "moment";

const PendingPaymentLockWarning = ({ lockTime }) => {
    return (
        <div className="alert alert-warning">
            Our records show there is a pending payment on this case on {moment(lockTime).format("L")}. Please wait for the system to process the payment.
        </div>
    )
}

export default PendingPaymentLockWarning