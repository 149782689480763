import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";
import { getCase } from "../../actions/case-action";
import { useMedia } from "react-media";
import GLOBAL_MEDIA_QUERIES from "../../media/GLOBAL_MEDIA_QUERIES";

import CaseInfoTable from "../../components/shared-components/CaseInfoTable";
import CaseBalanceTableCompact from "./components/CaseBalanceTableCompact";
import CaseBalanceTable from "./components/CaseBalanceTable";

const CaseBalance = ({ caseModel, getCase }) => {
  let { caseNumber } = useParams();
  let history = useHistory();
  const [selectedParty, setSelectedParty] = useState();
  const matches = useMedia({ queries: GLOBAL_MEDIA_QUERIES });

  useEffect(() => {
    const init = async () => {
      if (caseModel.odyCase == null) {
        const { odyCase: foundCase, pendingPaymentLock } = await getCase(
          caseNumber
        );

        // Has a lock, not found or no fee, redirects back to home
        if (
          pendingPaymentLock != null ||
          foundCase == null ||
          foundCase?.odyCaseParties?.length === 0
        ) {
          history.push("/");
          return;
        }
      }
    };
    init();
  }, [caseModel, history, caseNumber, getCase]);

  return (
    <>
      <div className="col">
        <button
          className="btn btn-light btn-block-if-sm"
          onClick={() => {
            history.push("/");
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> &nbsp;Back to Case Search
        </button>
        <hr />
        {/* Case basic info */}
        {caseModel.odyCase != null && (
          <CaseInfoTable odyCase={caseModel.odyCase} />
        )}
      </div>
      <div className="col">
        {/* Select party dropdown */}
        {caseModel.odyCase != null && (
          <div className="form-horizontal">
            <div className={`form-group`}>
              <div className="row">
                <div className="col field-mapper">
                  <select
                    className="form-control btn btn-outline-secondary"
                    onChange={(e) => {
                      setSelectedParty(
                        caseModel.odyCase.odyCaseParties.find(
                          (j) => j.partyID.toString() === e.target.value
                        )
                      );
                    }}
                  >
                    <option className="btn-select-option">
                      Select a Case Party
                    </option>
                    {caseModel.odyCase.odyCaseParties.map((p) => (
                      <option
                        key={p.partyID}
                        value={p.partyID}
                        className="btn-select-option"
                      >
                        {p.partyTypeDescription} - {p.partyName}{" "}
                        {p.totalBalances}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Fees */}
        {selectedParty != null && (
          <>
            {matches.bootstrap_xs ||
            matches.bootstrap_sm ||
            matches.bootstrap_md ? (
              // For small screen
              <CaseBalanceTableCompact
                selectedParty={selectedParty}
                caseModel={caseModel}
              />
            ) : (
              // For mid and up screen
              <CaseBalanceTable
                selectedParty={selectedParty}
                caseModel={caseModel}
              />
            )}
          </>
        )}

        {/* loading */}
        {caseModel.odyCase == null && (
          <>
            <div className="alert alert-primary">
              <FontAwesomeIcon
                icon={faCircleNotch}
                spin={true}
                style={{ width: "20px", height: "20px" }}
              />{" "}
              <span>&nbsp; Loading...</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return { caseModel: state.caseReducer.getCase };
}

const mapDispatchToProps = {
  getCase,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseBalance);
