import { combineReducers } from 'redux';
import { GET_TRANSACTION_DETAIL } from '../actions/transaction-action';

function transactionDetail(state = null, action) {
  switch (action.type) {
    case GET_TRANSACTION_DETAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const transactionReducer = combineReducers({
  transactionDetail
});

export default transactionReducer;

