import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./assets/styles/styles.scss";
import logo from "./assets/images/ejdc_header_1.5.jpg";
import MainForm from "./pages/PaymentForm/PaymentForm";
import Home from "./pages/Home/Home";
import CaseBalance from "./pages/CaseBalance/CaseBalance";
import Receipt from "./pages/Receipt/Receipt";
import { getEnvironement } from "./actions/signature-action";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEnvironement());
    return () => {};
  }, [dispatch]);

  return (
    <div className="container">
      <div className="d-flex flex-column main-flex">
        {/* Header */}
        <div className="header">
          <div className="fusion-logo">
            <a
              className="fusion-logo-link"
              href="http://www.clarkcountycourts.us/"
            >
              <img
                src={logo}
                style={{ width: "100%" }}
                alt="Eighth Judicial District Court"
                className="fusion-logo-1x fusion-standard-logo"
              />
            </a>
          </div>
        </div>

        {/* Body */}
        <div className="flex-grow-1">
          <Router>
            <Switch>
              <Route path="/case-balance/:caseNumber">
                <CaseBalance />
              </Route>
              <Route path="/payment-info/:caseNumber/:partyID/:feeScheduleCode">
                <MainForm />
              </Route>
              <Route path="/payment-info/:caseNumber/:partyID">
                <MainForm />
              </Route>
              <Route path="/receipt/:transactionID">
                <Receipt />
              </Route>
              <Route path="/">
                <Home />
              </Route>
              <Redirect to="/" />
            </Switch>
          </Router>
        </div>

        {/* Footer */}
        <div className="footer">
          <table>
            <tbody>
              <tr>
                <td>
                  <span style={{ fontSize: "12px" }}>
                    Regional Justice Center
                    <br />
                    200 Lewis Avenue
                    <br />
                    Las Vegas, NV 89155
                  </span>
                </td>
                <td>
                  <div style={{ marginLeft: "30px", fontSize: "12px" }}>
                    <span style={{ fontSize: "12px" }}>
                      Family Courts and Services
                    </span>
                    <br />
                    <span style={{ fontSize: "12px" }}>601 N. Pecos</span>
                    <br />
                    <span style={{ fontSize: "12px" }}>
                      Las Vegas, NV 89155
                    </span>
                    <br />
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan="3">
                  <div style={{ margin: "10px 0px", fontSize: "12px" }}>
                    <span className="">
                      &copy; 2016 Clark County Courts, Clark County, NV{" "}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);
