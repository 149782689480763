export const GET_TRANSACTION_DETAIL = "GET_TRANSACTION_DETAIL";

export const getTransactionDetail = (id) => async dispatch => {
    let transactionDetail;

    try {
        transactionDetail = await (await fetch(`/api/Response/GetTransactionDetail?id=${id}`)).json();

        await dispatch({
            type: GET_TRANSACTION_DETAIL,
            payload: {
                ...transactionDetail
            }
        });

        return transactionDetail;
    } catch(err) {
        return null;
    }
}